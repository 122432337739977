import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { logout } from "../../services/auth";
import { getGroupsImIn, iGroup } from "../../services/groups";
import { selectUser } from "../../store/userSlice";
import Button from "../dom/Button";
import settingsSvg from "../../assets/icons/settings.svg";
import userSvg from "../../assets/icons/user.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../dom/Loader";
import searchImg from "../../assets/icons/search.svg";

export default function UserDropdown() {
  const user = useSelector(selectUser);
  const [groups, setGroups] = useState<Array<iGroup>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [groupfilter, setGroupFilter] = useState("");

  const isAdmin = () => {
    return user.isAdmin === true;
  };

  const loadGroups = async () => {
    setIsLoading(true);
    try {
      const data = await getGroupsImIn({ page: 1, size: 1000 });
      setGroups(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user._id) return;

    loadGroups();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div id="user-dropdown">
      <div className="block">
        <div className="d-flex gap-2 justify-content-center">
          <img className="avatar" src={userSvg} alt="user icon" />
          <p className="email">{user.email}</p>
        </div>
        <Button
          small
          className="mt-2"
          onClick={() => navigate("/account/profile")}
          text={String(t("settings.manage_account"))}
        />
      </div>
      {isAdmin() ? (
        <>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Users"))}
              onClick={() => navigate("/users")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("Partners"))}
              onClick={() => navigate("/partners")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Emails"))}
              onClick={() => navigate("/emails")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("Support Faqs"))}
              onClick={() => navigate("/support")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Promo Codes"))}
              onClick={() => navigate("/promocodes")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("Invoices"))}
              onClick={() => navigate("/invoices")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Documents"))}
              onClick={() => navigate("/docs")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("Translations"))}
              onClick={() => navigate("/translations")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Nfts"))}
              onClick={() => navigate("/nfts")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("AI (Gennaro)"))}
              onClick={() => navigate("/ai")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Reports"))}
              onClick={() => navigate("/reports")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("Quote"))}
              onClick={() => navigate("/quote")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Collection Manager"))}
              onClick={() => navigate("/collection-manager")}
              className="w100 m-1"
            />
            <Button
              light
              small
              text={String(t("Email service"))}
              onClick={() => navigate("/emails-service")}
              className="w100 m-1"
            />
          </div>
          <div className="d-flex w100">
            <Button
              light
              small
              text={String(t("Hotel Registration"))}
              onClick={() => navigate("/hotel-registration-manager")}
              className="w100 m-1"
            />
          </div>
        </>
      ) : null}
      <div className="hr"></div>
      <span className="group-title">{t("group.your_groups_title")}</span>
      {isLoading ? <Loader scale={0.4} /> : null}
      {groups.length > 3 ? (
        <div className="w100 group-filter">
          <input
            className="w100 "
            type="text"
            value={groupfilter}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setGroupFilter(e.target.value)}
          />
          <img src={searchImg} alt="search icon" />
        </div>
      ) : null}
      <div className="group-list">
        {groups.length > 3 ? <div className="top-shadow"></div> : null}
        {(groupfilter
          ? groups.filter((g) =>
              g.name.toLowerCase().includes(groupfilter.toLowerCase())
            )
          : groups
        ).map((group, key) => {
          return (
            <div
              onClick={() => navigate("/group/" + group._id)}
              key={"group_" + key}
              className="group"
            >
              <small>{group.name}</small>
              {!group.verified ? (
                <small className="not-verified">
                  {t("group.not_verified")}
                </small>
              ) : null}
            </div>
          );
        })}
        {groups.length > 3 ? <div className="bottom-shadow"></div> : null}
      </div>
      <div onClick={() => navigate("/account/group")} className="create-group">
        <img src={settingsSvg} alt="settings icon" />
        <span>{t("group.manage_group")}</span>
      </div>
      <div className="hr"></div>
      <span onClick={() => navigate("/account/payment")} className="payment">
        {t("payment.payment_methods")}
      </span>
      <div className="hr"></div>
      <div className="block">
        <Button
          small
          error
          onClick={() => logout()}
          text={String(t("auth.logout"))}
        />
      </div>
    </div>
  );
}
